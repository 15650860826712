.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.marginBottonNone {
  margin-bottom: 0px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.seeThrough {
  opacity: 0.9;
}

.seeThroughWorkouts {
  opacity: 0.7;
}

.socialMedia-icon {
  width: 80%;
  height: auto;
}

.greyHighlight {
  background-color: grey;
}

.paddingBottom {
  margin-top: 55px;
  margin-bottom: 15px;
}

.appLogos {
  width: 100%;
  height: auto;
}

.appNames {
  text-decoration: underline;
}

.fitting {
  overflow-y: auto;
}

.bodyProjectImg {
  width: 70%;
  height: auto;
}

.workoutImgs {
  width: 70%;
  height: auto;
}

.circlePic {
  border-radius: 50%;
}

.aboutmePic {
  height: auto;
  width: 30%;
}

.workoutScreen {
  min-height: 100vh;
}

.customWorkoutImg {
  margin-top: 10px;
  margin-bottom: 10px;
}

.proteinImg {
  width: 90%;
  height: auto;
  margin-top: 0px;
  margin-bottom: 15px;
}

.activeImg {
  width: 75%;
  height: auto;
}

.alcoholImg {
  width: 70%;
  height: auto;
}

.foodImg {
  width: 90%;
  height: auto;
}

.adviceImg {
  width: 70%;
  height: auto;
}

.meAdviceImg {
  width: 75%;
  height: auto;
}

.cheatMeal {
  width: 75%;
  height: auto;
}

.sleepImg {
  width: 85%;
  height: auto;
}

.coachName {
  text-decoration: underline;
  text-align: left;
}

.youtubeVid {
  margin-top: -65px;
  max-height: 450px !important;
}

.bannerHeight {
  max-height: 200px !important;
  height: 100%;
}

.workoutMarginUpImgs {
  margin-top: -45px;
}

.totalTimeMargin {
  margin-top: 25px;
}

.calorieCycleIcon {
  width: 73%;
  height: auto;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -10;
  opacity: 50%;
}

#outlined-basic {
  padding-left: 10px !important;
  border-bottom: none !important;
  box-shadow: none !important;
  margin: 0 0 0 0 !important;
  height: 50px !important;
}
#outlined-basic:after {
  border-bottom: none !important;
  box-shadow: none !important;
  margin: 0 0 0 0 !important;
}

#outlined-basic:focus {
  border-bottom: none !important;
  box-shadow: none !important;
  margin: 0 0 0 0 !important;
}
#outlined-basic:before {
  border-bottom: none !important;
  box-shadow: none !important;
  margin: 0 0 0 0 !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  background-color: transparent !important;
}

#timeDropdown:after {
  border-bottom: none !important;
}

#timeDropdown:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}

#timeDropdown:before {
  border-bottom: none !important;
}

#timeDropdown {
  margin-top: 4px !important;
  margin-bottom: none !important;
  border-bottom: none !important;
  max-height: 3px !important;
}

#timeChoices {
  max-height: 50px !important;
}

#timeDropdown-label {
  max-height: 50px !important;
}

.instaLogo {
  width: 35%;
  height: auto;
}

.youTubeLogo {
  width: 30%;
  height: auto;
}

.metaLogo {
  width: 40%;
  height: auto;
}

.pb2Icon {
  width: 80%;
  height: auto;
}

.pb2Icon2 {
  width: 100%;
  height: auto;
  margin-top: 15px;
}

.proteinBarsIcon {
  width: 100%;
  height: auto;
  margin-top: 20px;
}
.proteinBarsZoneP {
  width: 100%;
  height: auto;
  margin-top: 35px;
}

.proteinPuffs {
  width: 73%;
  height: auto;
}

.riceCakesImg {
  width: 60%;
  height: auto;
}

.fruitImg {
  width: 100%;
  height: auto;
  margin-top: 10px;
}

.lowCalImg {
  width: 83%;
  height: auto;
}

.mirrorBtn {
  margin-top: 25px !important;
}

.paddingBottomPlayList {
  margin-top: 280px !important;
}

.background-videoMirror {
  width: 100vw !important;
  height: 100vh !important;
  object-fit: cover !important;
  position: fixed !important;
  opacity: 0.9 !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1 !important;
}

/* .builderInput {
  margin-bottom: -10 !important;
  margin: 0px !important;
} */

/* .timeDropdown {
  border-bottom: none !important;
  margin: 0px !important;
  max-height: 50px !important;
  text-decoration: none !important;
}

.timeDropsize {
  max-height: 50px !important;
  border-bottom: none !important;
  text-decoration: none !important;
} */

.progressbar-container {
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.progressbar-complete {
  position: absolute;
  left: 0;
  top: 0px;
  height: 100%;
  background-color: #25bd39;
  border-radius: 10px;
  animation: g 2500ms infinite ease-in-out;
  z-index: 2;
}
.progressbar-liquid {
  z-index: 1;
  width: 70px;
  height: 70px;
  animation: g 2500ms infinite ease-in-out,
    r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
  position: absolute;
  right: -5px;
  top: -10px;
  background-color: #25bd46;
  border-radius: 40%;
}

.progress {
  z-index: 2;
}

@keyframes g {
  0% {
    background-color: #25bd88;
  }
  50% {
    background-color: #609e6e;
  }
  100% {
    background-color: #6ebd25;
  }
}

@keyframes r {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}

.progressbar-complete-red {
  position: absolute;
  left: 0;
  top: 0px;
  height: 100%;
  background-color: #f70c03;
  border-radius: 10px;
  animation: p 2500ms infinite ease-in-out;
  z-index: 2;
}
.progressbar-liquid-red {
  z-index: 1;
  width: 70px;
  height: 70px;
  animation: p 2500ms infinite ease-in-out,
    r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
  position: absolute;
  right: -5px;
  top: -10px;
  background-color: #df1414;
  border-radius: 40%;
}

@keyframes p {
  0% {
    background-color: #f13e26;
  }
  50% {
    background-color: #d41e18;
  }
  100% {
    background-color: #f80c03;
  }
}

@media only screen and (max-width: 600px) {
  .bodyProjectImg {
    width: 70%;
    height: auto;
    margin-top: 55px;
    margin-bottom: 15px;
  }
  .appLogos {
    width: 60%;
    height: auto;
  }

  .paddingBottomApps {
    padding-bottom: 10px;
  }

  .adviceImg {
    width: 50%;
    height: auto;
    margin-top: 55px;
    margin-bottom: 15px;
  }

  .youtubeVid {
    margin-top: -65px;
  }

  .lastVidHeight {
    margin-top: 200px !important;
    padding-top: 180px !important;
  }

  .paddingBottom {
    margin-top: 75px !important;
    margin-bottom: 15px;
  }

  .fitText {
    font-size: 0.4em;
  }

  .proteinImg {
    width: 60%;
    height: auto;
    margin-top: 25px;
    margin-bottom: 5px;
  }

  .activeImg {
    width: 60%;
    height: auto;
  }

  .alcoholImg {
    width: 50%;
    height: auto;
  }

  .foodImg {
    width: 65%;
    height: auto;
  }

  .cheatMeal {
    width: 60%;
    height: auto;
  }

  .sleepImg {
    width: 60%;
    height: auto;
  }

  .paddingBottom {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .marginUp {
    margin-top: -25px;
  }

  .workoutImgs {
    width: 70%;
    height: auto;
  }

  .workoutMarginUpImgs {
    margin-top: 0px;
  }

  .parallax-container {
    max-height: 280px;
  }

  .coachName {
    text-decoration: underline;
    text-align: center;
  }

  #outlined-basic {
    padding-left: 5px !important;
    border-bottom: none !important;
    box-shadow: none !important;
    margin: 0 0 0 0 !important;
    height: 50px !important;
  }

  .alignButton {
    margin-top: 25px !important;
  }

  .lowCalImg {
    width: 60%;
    height: auto;
  }

  .aboutMeImages {
    height: auto !important;
    width: 55% !important;
  }

  .logoSize {
    width: 200% !important;
    height: 200% !important;
  }
  .calorieCycleIcon {
    width: 55%;
    height: auto;
  }
}
